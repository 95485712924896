import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDirective } from './error/error.directive';
import { HandleImgErrorDirective, HandleAvatarErrorDirective } from './handle-img-error/handle-img-error.directive';
import { BackBtnDirective } from './back-btn/back-btn.directive';
import { StatusColorDirective } from './status-color/status-color.directive';


@NgModule({
  declarations: [
    ErrorDirective,
    HandleImgErrorDirective,
    HandleAvatarErrorDirective,
    BackBtnDirective,
    StatusColorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ErrorDirective,
    HandleImgErrorDirective,
    HandleAvatarErrorDirective,
    BackBtnDirective,
    StatusColorDirective
  ]
})
export class DirectivesModule { }
