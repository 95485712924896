import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixPanelService {

  private mixPanelToken: string = environment.mixpanelToken;

  constructor() {
    if (this.mixPanelToken) {
      mixpanel.init(this.mixPanelToken);
    }
  }

  //timeSpent initialization
  timeEvent(eventName: string): void {
    mixpanel.time_event(eventName);
    console.log(`time_event: ${eventName}`);
  }

  // Track events
  track(eventName: string, properties?: any): void {
    mixpanel.track(eventName, properties);
    console.log(`track: ${eventName}`);
  }

  //   public trackEvent(eventName: string, properties?: any): void {
  //     if (environment.analytics) {
  //       mixpanel.track(eventName, properties);
  //     }
  //   }

  //   public setPersonProperties(properties: any): void {
  //     if (environment.analytics) {
  //       mixpanel.people.set(properties);
  //     }
  //   }

  //   public getIdentify(): any {
  //     if (environment.analytics) {
  //       return mixpanel.get_distinct_id();
  //     }
  //   }

  public setPersonProperties(properties: any): void {
    mixpanel.people.set(properties);
  }

  public getIdentify(): any {
    return mixpanel.get_distinct_id();
  }

  public setIdentify(userId: string): void {
    mixpanel.identify(userId);
  }

  //   public resetMixPanel(): void {
  //     if (environment.analytics) {
  //       mixpanel.reset();
  //     }
  //   }

  //   public aliasId(userId: string): void {
  //     if (environment.analytics) {
  //       mixpanel.alias(userId);
  //     }
  //   }

  //   public mergeId(newId: string, prevId: string): void {
  //     if (environment.analytics) {
  //       mixpanel.alias(newId, prevId);
  //     }
  //   }

  //   public trackPageStatus(pageName: string, isPageOpen: boolean) {
  //     if (environment.analytics) {
  //       this.trackEvent(Page - ${ isPageOpen? 'Open': 'Close' }, { Info: ${ pageName } page ${ isPageOpen? 'opened': 'closed' } successfully. });
  //   }
  // }

  //   public trackDialogStatus(dialogName: string, isDialogOpen: boolean) {
  //   if (environment.analytics) {
  //     this.trackEvent(Dialog - ${ isDialogOpen? 'Open': 'Close' }, { Info: ${ dialogName } dialog ${ isDialogOpen? 'opened': 'closed' } successfully. });
  // }
  //   }
}
