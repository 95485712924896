import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { resolve } from 'url';
import { SnackBarService } from '../snackbar/snack-bar.service';
import { DataService } from '../data/data.service';
import { MixPanelService } from '../mix-panel/mix-panel.service';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  constructor(
    private userService: UserService,
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private snackBar: SnackBarService,
    private dataService: DataService,
    private mixpanelService: MixPanelService
  ) { }

  init() {
    var initPromise: Promise<boolean>[] = [];


    initPromise.push(new Promise((resolve, reject) => {
      this.fireAuth.user.subscribe(currentUser => {
        console.log("current User", currentUser);
        if (currentUser) {
          this.userService.get(currentUser.uid).subscribe(res => {
            if (res) {
              this.userService._currentUser.next(res[0]);
              let user = res[0].firebaseUid;
              this.mixpanelService.setIdentify(user);

              let userData = { "$name": res[0].name, "$email": res[0].email, userUniqueId: res[0].userUniqueId };
              this.mixpanelService.setPersonProperties(userData);
              // console.log("getIdentify", this.mixpanelService.getIdentify());
            }
            resolve(true);
          }, error => {
            // this.snackBar.info(error.error);
            resolve(true);
          });
        } else {
          let anonymousUser = this.userService.getAnonymousUser();
          if (!anonymousUser) {
            anonymousUser = this.userService.createAnonymousUser();
          }
          this.userService._currentUser.next(anonymousUser);
          resolve(true)
        }
      });
    }));
    let appLanguage;
    if (localStorage.getItem('appLanguage')) {
      appLanguage = localStorage.getItem('appLanguage');
    } else {
      appLanguage = "ENGLISH";
    }

    this.dataService.getAppLanguage(appLanguage).subscribe(res => {
      if (res[0]?.disable === true) {
        this.dataService.getAppLanguage('ENGLISH').subscribe(res1 => {
          console.log("App language", res1);
          this.dataService._appLanguagesData.next(res1)
        });
      } else {
        console.log("App language", res);
        this.dataService._appLanguagesData.next(res)
      }
    })

    // initPromise.push(new Promise((resolve, reject) => {

    //   const collection = this.firestore.collection('users');

    //   collection.get().subscribe(res => {

    //     let users = [];

    //     res.forEach(user => {

    //       users.push({
    //         id: user.id,
    //         data: user.data()
    //       });

    //     });

    //     var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(users));
    //     var dlAnchorElem = document.createElement('a');
    //     dlAnchorElem.setAttribute("href", dataStr);
    //     dlAnchorElem.setAttribute("download", "scene.json");
    //     dlAnchorElem.click();

    //   });

    // }));

    return Promise.all(initPromise);
  }
}

export function initProviderFactory(provider: InitializerService) {
  return () => provider.init();
}
