import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
// import { SwPush } from '@angular/service-worker';
import { AuthService } from './core/services/auth/auth.service';
import { DataService } from './core/services/data/data.service';
import { IconRegisterService } from './core/services/icon-register/icon-register.service';
import { PushNotificationService } from './core/services/push-notification/push-notification.service';
import { Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'biblist-app';
  message;

  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;

  noInternet: boolean = true;

  public installPrompt: any;

  constructor(
    private iconRegister: IconRegisterService,
    private authService: AuthService,
    // private messagingService: PushNotificationService,
    private swUpdate: SwUpdate,
    private dataService: DataService,

    private metaTitle: Title, 
    private meta: Meta
  ) {
    this.iconRegister.registerIcons();
    if (this.swUpdate.isEnabled) {
      try {
        this.swUpdate.checkForUpdate();
        this.swUpdate.available.subscribe(ele => {
          // console.log(ele);
          if (confirm("Update Available, Do you want to reload?")) {
            this.swUpdate.activateUpdate().then(() => {
              location.reload();
            })
          }
        });
      } catch (error) {
        console.error(error);
      }
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      console.log('install prompt',e)
      this.installPrompt = e;

      // this.installPrompt.prompt();
      // Save the event because you'll need to trigger it later.
      // deferredPrompt = e;
      // Show your customized install prompt for your PWA
      // Your own UI doesn't have to be a single element, you
      // can have buttons in different locations, or wait to prompt
      // as part of a critical journey.
      // showInAppInstallPromotion();
      this.askInstallPrompt();
    });

  }

  askInstallPrompt() {
    console.log("installPrompt");
    this.installPrompt.prompt();
  }

  // pushSubcription(){
  //   if(!this.swPush.isEnabled){
  //     console.log("Not Enabled Push Notification");
  //     return;
  //   }
  // }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        console.log('status', status);
        this.networkStatus = status;
        this.dataService._networkEvent.next(status);
      });
  }

  ngOnInit() {
    // this.pushSubcription();
    this.authService.getToken();
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage;

    // this.metaTitle.setTitle(" Are you already a Biblist?");
    // this.meta.removeTag('name="title"');
    // this.meta.removeTag("image");
    // this.meta.removeTag('name="description"');

    this.meta.updateTag({ name: 'og:title', content: 'Are you already a Biblist?' });

    this.meta.updateTag({ name: 'og:description', content: 'Biblist - app is a game based app' });
    // this.meta.addTag({ name: 'og:image', content: 'assets/images/biblist-logo.png' });


    this.dataService.networkEvent.subscribe(res => {
      this.noInternet = res;
    })

    this.checkNetworkStatus();

    this.dataService.appLanguagesData.subscribe(res => {
      console.log("app language data", res);
    })
  }
}
